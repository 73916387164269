<template>
	<div>
		<van-nav-bar title="转换记录" left-text="返回" left-arrow @click-left="onClickLeft" :fixed="true" />
		<van-list class="good-list" v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" offset="80">
            <van-card v-for="item in historyList" :key="item.tao_id" :title="item.title" :thumb="item.pict_url" :tag="typeArr[item.type]">
				<template #footer v-if="item.type==1">
                    <van-button size="mini" @click="setCopyLink(item)">生成口令</van-button>
                </template>
            </van-card>
        </van-list>
        <van-dialog v-model="showDialog" :show-confirm-button="false">
            <van-field class="tkl-content" v-model="newTKL" type="textarea" rows="4" placeholder="转换后口令" />
            <span class="copy-text" :data-clipboard-text="newTKL" @click="copyTKL">一键复制</span>
        </van-dialog>
	</div>
</template>

<script>
import Clipboard from "clipboard";

export default {
	data() {
		return {
            typeArr:['','淘宝','京东'],
			historyList: [],
            pageindex: 1,
            page_size: 6,
            loading: false,
            finished: false,
            newTKL: '',
            showDialog: false,
            relation_id: ''
		}
	},
	created() {
		this.onLoad()
        this.relation_id = this.$store.state.user.userInfo.relation_id
	},
	methods: {
		onLoad() {
			if(this.finished) {
                this.loading = false;
				return false;
			}
            let params = { pageindex: this.pageindex, page_size: this.page_size }
            this.loading = true
            this.$ask.post('/getHistory', params)
                .then(res => {
                    // 数据全部加载完成
                    if (!res.data || res.data.length == 0) {
                        this.finished = true;
                    }
                    else {
                        this.pageindex++;
						this.historyList = this.historyList.concat(res.data)
                    }
                    // 加载状态结束
                    this.loading = false;
                })
                .catch(this.$toastError)
        },
		setCopyLink(item) {
            let params = { num_iid: item.tao_id }
            if(this.relation_id) params.relation_id = this.relation_id
            this.$ask.post('/convertTKLByID', params)
                .then(res => {
                    const resData = res.data
                    this.newTKL = `${resData.tkl} ${resData.shorturl2} ${resData.title}`
                    this.showDialog = true;
                })
                .catch(this.$toastError)
        },
        copyTKL() {
            let clipboard = new Clipboard(".copy-text");
            let self = this;
            clipboard.on("success", () => {
                self.$toast("复制成功");
                self.showDialog = false;
                clipboard.destroy();
            });
            clipboard.on("error", () => {
                self.$toast("该浏览器不支持自动复制");
                self.showDialog = false;
                clipboard.destroy();
            });
        },
        onClickLeft(){
            this.$routerGo(-1)
        }
	}
}
</script>

<style lang="scss" scoped>
.good-list{
    padding-top: 46px;
}
.copy-text {
    text-align: center;
    display: block;
    line-height: 28px;
    padding: 10px;
    color: #4fc08d;
}
</style>